/* General body styling */
body {
    font-family: Arial, sans-serif; /* Clean sans-serif font */
    background-color: #f4f4f4; /* Light grey background */
    margin: 0;
    padding: 20px;
  }
  
  /* Main container styling */
  div {
    max-width: 600px; /* Maximum width of the form */
    margin: 0 auto; /* Centering the form */
    padding: 20px;
    background-color: #ffffff; /* White background for the form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow */
  }
  
  /* Styling headings */
  h1 {
    color: #333; /* Dark grey color for text */
    text-align: center; /* Center-aligned heading */
  }
  
  /* Form element styling */
  form {
    display: grid; /* Using grid layout */
    gap: 20px; /* Space between grid items */
    padding: 20px;
  }
  
  /* Label styling */
  label {
    display: block; /* Ensure labels are block level */
    margin-bottom: 5px; /* Space below the label */
    color: #555; /* Medium grey text color */
  }
  
  /* Input and Select field styling */
  input, select, textarea {
    width: 100%; /* Full width fields */
    padding: 10px; /* Padding inside fields */
    margin-top: 5px; /* Space above fields */
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 4px; /* Rounded borders for inputs */
  }
  
  /* Button styling */
  button {
    background-color: #0056b3; /* Blue background */
    color: white; /* White text */
    padding: 10px 15px; /* Padding */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  button:hover {
    background-color: #004494; /* Darker blue on hover */
  }
  